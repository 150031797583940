<template>
    <b-overlay :show="showLoader">
        <b-card>
            <h2 style="font-weight: bold;">Izpolnite podatke o storitvi</h2>
            <validation-observer ref="validate">
                <b-form class="form mt-1">
                    <Service ref="service" v-model="object" type="edit" :serviceProviders="serviceProviders"/>
                    <b-form-group class="text-right">
                        <b-button variant="secondary" @click.prevent="validationForm">UREDI STORITEV</b-button>
                    </b-form-group>
                </b-form>
            </validation-observer>
        </b-card>
    </b-overlay>
</template>

<script>
    import { ValidationObserver } from 'vee-validate'
    import Service from '@/views/Components/Service'
    import {BButton, BCard, BForm, BFormGroup, BOverlay} from 'bootstrap-vue'
    export default {
        components: {
            Service,
            ValidationObserver,
            BOverlay,
            BCard,
            BForm,
            BFormGroup,
            BButton
        },

        data() {
            return {
                object: {
                    title:'',
                    author_id:'',
                    date_added:'',
                    content:'',
                    instructions:'',
                    additional_photos: [],
                    main_photo: '',
                    active: true,
                    usage_type: 0,
                    service_provider: ''
                },
                showLoader: false,
                serviceProviders: []
            }
        },
        methods: {
            loadData() {
                this.showLoader = true

                try {
                    this.getService()
                    this.loadServiceProviders()
                } catch (error) {
                    this.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            },
            getService() {
                const thisIns = this
                this.$http.get(`/api/management/v1/service/${thisIns.$route.params.service_id}`)
                    .then(response => {
                        this.object = response.data
                    })
                    .catch(error => {
                        thisIns.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                    })
            },
            async validationForm() {
                this.$refs.validate.validate().then(async(success) => {
                    if (success) {
                        await this.editService()
                    }
                })

            },
            async editService() {
                this.showLoader = true

                try {
                    if (this.object.usage_type === 0 && this.object.service_provider !== '') {
                        const provider = this.serviceProviders.find(x => x.id === this.object.service_provider)
                        if (provider.contact_person.name === '' || provider.contact_person.email === '' || provider.contact_person.telephone_number === '') {
                            this.$printWarning('Ponudnik storitve mora imeti izpolnjena polja o kontaktni osebi')
                            return
                        }
                    }

                    const isImageUploadSuccessfull = await this.$refs.service.$refs.imageSelector.UploadImagesToAPI()
                    if (!isImageUploadSuccessfull) {
                        this.$printError('Prišlo je do napake pri nalaganju slik.')
                        return
                    }

                    this.object.author_id = this.$store.state.user.userData.id
                    this.object.date_added = new Date()

                    await this.$http.patch(`/api/management/v1/service/${this.$route.params.service_id}`, this.object)
                    this.$printSuccess('Storitev je bila uspešno urejena!')

                    setTimeout(() => {
                        this.$router.push({name: 'admin-service-list'})
                    }, 1000)
                } catch (error) {
                    this.$printError(error.response.data)
                } finally {
                    this.showLoader = false
                }
            },
            loadServiceProviders() {
                const thisIns = this

                this.$http.get('/api/management/v1/service/provider/')
                    .then(response => {
                        this.serviceProviders = response.data ?? []
                    })
                    .catch(error => {
                        thisIns.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                    })
            }
        },
        created() {
            this.loadData()
        }
    }
</script>
